<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div v-if="menus !== null">
      <b-modal
          id="modal-delete"
          centered
          title="حذف"
          ok-title="بله"
          cancelTitle="خیر"
          @ok="deleteMenu"
      >
        <span>آیا از حذف کردن این آیتم اطمینان دارید ؟ </span>
      </b-modal>
      <b-modal
          title="بروزرسانی"
          id="modal-edit"
          centered
          ok-title="بروزرسانی فهرست"
          ok-only
          @ok.preventDefault()="updateMenu"
      >
        <b-row v-if="selectedMenu">
          <b-col cols="12">
            <b-form-group>
              <label>نام </label>
              <b-form-input
                  type="text"
                  placeholder="نام "
                  v-model="selectedMenu.title"
              />
            </b-form-group>
            <b-form-group>
              <label>لینک</label>
              <b-form-input
                  type="text"
                  dir="ltr"
                  placeholder="لینک"
                  v-model="selectedMenu.link"
              />
            </b-form-group>
            <!--            <b-form-group>-->
            <!--              <label for="email">دسته بندی اصلی</label>-->
            <!--              <v-select-->
            <!--                  v-model="selectedMenu.parentId"-->
            <!--                  :options="ProductCategories"-->
            <!--                  label="name"-->
            <!--                  :reduce="name => name.productCategoryId"-->
            <!--                  class="per-page-selector d-inline-block w-100"-->
            <!--              />-->
            <!--            </b-form-group>-->
          </b-col>
        </b-row>


      </b-modal>
      <b-modal
          id="modal-Add"
          centered
          ok-title="افزودن فهرست جدید"
          ok-only
          @ok.preventDefault()="addMenu"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <label>نام </label>
              <b-form-input
                  type="text"
                  placeholder="نام"
                  v-model="newMenu.title"
              />
            </b-form-group>
            <b-form-group>
              <label>لینک</label>
              <b-form-input
                  type="text"
                  dir="ltr"
                  placeholder="لینک"
                  v-model="newMenu.link"
              />
            </b-form-group>
            <b-form-group>
              <label>فهرست مادر (اختیاری)</label>
              <v-select
                  v-model="newMenu.parentId"
                  :options="menus.filter(e=> e.parentId===0)"
                  label="title"
                  :reduce="title => title.menuId"
                  class="per-page-selector d-inline-block w-100"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
      <!--  SubMenus    -->
      <b-modal
          v-if="showSubsModal"
          title="زیرمنوها"
          id="modal-SubMenus"
          centered
          size="lg"
          ok-title="بستن"
          ok-only
      >
        <b-row>
          <b-col cols="12">
            <b-table
                ref="refUserListTable"
                class="position-relative my-2"
                :items="subs"
                responsive
                :fields="myTableColumns"
                primary-key="id"
                show-empty
                bordered
                striped
                empty-text="موردی موجود نیست!"
            >

              <!-- Column: delete -->

              <template #cell(Delete)="data">

                <div class="cursor-pointer d-flex flex-row"
                     v-b-modal.modal-delete
                     @click="setSelectedMenu(data.item)"
                >
                  <feather-icon icon="TrashIcon" size="20" class="text-danger"/>
                </div>
              </template>
              <template #cell(link)="data">

                <span dir="ltr">{{ data.item.link }}</span>

              </template>
              <template #cell(Edit)="data">

                <div class="cursor-pointer d-flex flex-row"
                     v-b-modal.modal-edit
                     @click="setSelectedMenu(data.item)"
                >
                  <feather-icon class="text-primary" icon="SettingsIcon" size="20"/>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-modal>
      <!--  Add SubMenus   -->
      <b-modal
          id="modal-Add-Sub"
          centered
          title="افزودن زیرمنو جدید"
          ok-title="ثبت"
          ok-only
          @ok.preventDefault()="addMenu"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <label>نام </label>
              <b-form-input
                  type="text"
                  placeholder="نام"
                  v-model="newMenu.title"
              />
            </b-form-group>
            <b-form-group>
              <label>لینک</label>
              <b-form-input
                  type="text"
                  dir="ltr"
                  placeholder="لینک"
                  v-model="newMenu.link"
              />
            </b-form-group>
<!--            <b-form-group>-->
<!--              <label>فهرست مادر (اختیاری)</label>-->
<!--              <v-select-->
<!--                  v-model="newMenu.parentId"-->
<!--                  :options="menus"-->
<!--                  label="title"-->
<!--                  :reduce="title => title.menuId"-->
<!--                  class="per-page-selector d-inline-block w-100"-->
<!--              />-->
<!--            </b-form-group>-->
          </b-col>
        </b-row>
      </b-modal>
      <!-- Table Container Card -->
      <b-card
          no-body
          class="mb-3"
      >
        <b-col lg="12" md="12" class="my-2">
          <b-row>
            <b-col lg="10" md="12" class="my-2">
              <b-form-input
                  id="basicInput"
                  v-model="search"
                  placeholder="جستجو بر اساس نام فهرست"
              />
            </b-col>
            <b-col lg="2" md="12" class="my-2">
              <b-button

                  variant="success"
                  v-b-modal.modal-Add

              >
                افزودن فهرست اصلی
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        </b-card>

        <b-card
            no-body
            class="mb-3"
        >
          <h1 class="mt-2 mx-2">فهرست های اصلی</h1>
        <b-table
            ref="refUserListTable"
            class="position-relative my-2"
            :items="menus.filter(e=> e.parentId===0)"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
        >

          <!-- Column: delete -->

          <template #cell(Delete)="data">

            <div class="cursor-pointer d-flex flex-row"
                 v-b-modal.modal-delete
                 @click="setSelectedMenu(data.item)"
            >
              <feather-icon icon="TrashIcon" size="20" class="text-danger"/>
            </div>

          </template>
          <template #cell(link)="data">

            <span dir="ltr">{{ data.item.link }}</span>

          </template>
          <template #cell(Edit)="data">

            <div class="cursor-pointer d-flex flex-row"
                 v-b-modal.modal-edit
                 @click="setSelectedMenu(data.item)"
            >
              <feather-icon class="text-primary" icon="SettingsIcon" size="20"/>
            </div>

          </template>

        </b-table>
      </b-card>

      <b-card
          no-body
          class="mb-3"
          v-for="menu in menus.filter(e=> e.parentId===0)"
          :key="menu.menuId"
      >
       <b-row class="justify-content-between px-3">
         <h2 class="mt-2 ">زیرمنوهای <span class="text-primary">{{menu.title}}</span></h2>
         <b-button
             class="h-50 mt-1"
             variant="primary"
             v-b-modal.modal-Add-Sub
             @click="showAddSub(menu.menuId)"
         >
           افزودن زیر منو اصلی
         </b-button>
       </b-row>

        <b-table
            ref="refUserListTable"
            class="position-relative my-2"
            :items="menus.filter(e=> e.parentId===menu.menuId)"
            responsive
            :fields="myTableSubMenuColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
        >
          <!-- Column: delete -->

          <template #cell(Delete)="data">

            <div class="cursor-pointer d-flex flex-row"
                 v-b-modal.modal-delete
                 @click="setSelectedMenu(data.item)"
            >
              <feather-icon icon="TrashIcon" size="20" class="text-danger"/>
            </div>

          </template>
          <template #cell(link)="data">

            <span dir="ltr">{{ data.item.link }}</span>

          </template>
          <template #cell(Edit)="data">

            <div class="cursor-pointer d-flex flex-row"
                 v-b-modal.modal-edit
                 @click="setSelectedMenu(data.item)"
            >
              <feather-icon class="text-primary" icon="SettingsIcon" size="20"/>
            </div>

          </template>

          <template #cell(subs)="data">

            <div class="cursor-pointer d-flex flex-row"
                 v-b-modal.modal-SubMenus
                 @click="showSubs(data.item.menuId)"
            >
              <feather-icon class="text-primary" icon="EditIcon" size="20"/>
            </div>
          </template>

          <template #cell(addSubs)="data">

            <div class="cursor-pointer d-flex flex-row"
                 v-b-modal.modal-Add-Sub
                 @click="showAddSub(data.item.menuId)"
            >
              <feather-icon class="text-primary" icon="PlusCircleIcon" size="20"/>
            </div>
          </template>
        </b-table>
      </b-card>
    </div>

  </b-overlay>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormFile,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BModal, BFormGroup, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {GetAllMenu, CreateMenu, UpdateMenu, DeleteMenu} from "@/libs/Api/menu";

export default {
  title: 'فهرست ها   - پنل ادمین انزا مد',
  name: 'UsersList',
  data() {
    return {
      menus: null,
      showOverlay:false,
      totalCount: null,
      ProductCategories: null,
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: 'menuId',
          label: 'شناسه'
        },
        {
          key: 'title',
          label: 'نام'
        },
        {
          key: 'link',
          label: 'لینک'
        },
        {
          key: 'parentId',
          label: 'فهرست مادر'
        },
        {
          key: 'Delete',
          label: 'حذف'
        },
        {
          key: 'Edit',
          label: 'ویرایش'
        },
      ],
      myTableSubMenuColumns: [
        {
          key: 'menuId',
          label: 'شناسه'
        },
        {
          key: 'title',
          label: 'نام'
        },
        {
          key: 'link',
          label: 'لینک'
        },
        {
          key: 'parentId',
          label: 'فهرست مادر'
        },
        {
          key: 'subs',
          label: 'نمایش زیرمنوها'
        },
        {
          key: 'addSubs',
          label: 'افزودن زیرمنو'
        },
        {
          key: 'Delete',
          label: 'حذف'
        },
        {
          key: 'Edit',
          label: 'ویرایش'
        },
      ],
      newMenu: {
        title: '',
        link: '',
        createDate: new Date(Date.now()),
        parentId: 0
      },
      currentPage: 1,
      perPage: 10,
      search: '',
      selectedMenu: null,
      subs:[],
      showSubsModal:false,
    }
  },
  async created() {
    await this.getAllMenu()
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
  },
  watch: {
    search: function () {
      this.getAllMenu()
    },
    currentPage: function () {
      this.getAllMenu()
    }
  },
  methods: {
    async getAllMenu() {
      let _this = this
      _this.showOverlay = true;

      let getAllMenu = new GetAllMenu(_this)
      let data = {
        pageNumber: 0,
        searchCommand: _this.search,
        count: 0,
        parentId: ''
      }
      getAllMenu.setParams(data)
      await getAllMenu.fetch(function (content) {
        _this.menus = content.menus
        _this.totalCount = content.menusCount;
        _this.showOverlay = false;
      }, function (error) {
        console.log(error);
        _this.showOverlay = false;
      })
    },
    async updateMenu() {
      let _this = this
      _this.showOverlay = true;

      let updateMenu = new UpdateMenu(_this);
      updateMenu.setData(_this.selectedMenu)
      await updateMenu.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `آیتم بروزرسانی شد`,
          },
        })
        _this.selectedMenu = null
        _this.getAllMenu();
        _this.showOverlay = false;
      }, function (error) {
        console.log(error);
        _this.showOverlay = false;
      })
    },
    async addMenu() {
      let _this = this;
      _this.showOverlay = true;

      if (_this.newMenu.title !== '') {
        let createMenu = new CreateMenu(_this)
        createMenu.setData(_this.newMenu);
        await createMenu.fetch(function (content) {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `آیتم ساخته  شد`,
            },
          })
          _this.getAllMenu();
          _this.newMenu = {
            title: '',
            link: '',
            createDate: new Date(Date.now()),
            parentId: 0
          }
          _this.showOverlay = false;
        }, function (error) {
          console.log(error);
          _this.showOverlay = false;
        })
      } else {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: `لطفا همه فیلد هارا تکمیل کنید`,
          },
        })
        _this.showOverlay = false;
      }
    },
    async deleteMenu() {
      let _this = this
      _this.showOverlay = true;

      let deleteMenu = new DeleteMenu(_this)
      deleteMenu.setParams({id: this.selectedMenu.menuId})
      await deleteMenu.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `آیتم  حذف شد.`,
          },
        })
        _this.getAllMenu();
        _this.showOverlay = false;
      }, function (error) {
        console.log(error);
        _this.showOverlay = false;
      })
    },
    setSelectedMenu(item) {
      this.selectedMenu = JSON.parse(JSON.stringify(item))
    },
    showSubs(parentId){
      let _this = this;

      _this.subs = [];
      _this.menus.forEach(el => {
        if (el.parentId===parentId){
          _this.subs.push(el);
        }
      });
      _this.showSubsModal = true;
    },
    showAddSub(parentId){
      let _this = this;

      _this.newMenu.parentId = parentId;
      console.log(_this.newMenu.parentId)
    },
  },
}
</script>

<style scoped>

</style>
