import WebServiceRequest from '../Api/WebServiceRequest'

class GetAllMenu extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`Menu/GetAll`);
    }

    setParams(data) {
        super.setRequestParam(data)
    }
}

class DeleteMenu extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`Menu/DeleteBy`);
    }

    setParams(data) {
        super.setRequestParam(data)
    }
}


class UpdateMenu extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Menu/Update');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

class CreateMenu extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Menu/Create');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

export {
    UpdateMenu,
    DeleteMenu,
    GetAllMenu,
    CreateMenu,
};
